*,
a:hover {
  outline: 0;
}

body,
p {
  font-family: Inter, sans-serif;
  color: #000;
  font-weight: 400;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-weight: 400;
}

*,
.main-menu .navigation,
figure,
li,
p,
ul {
  margin: 0;
}

.nav-text,
li,
ul {
  list-style: none;
}

.blink-link,
.dropdown-content a,
.dropdown-content2 a,
.nav-text a,
a,
a:hover {
  text-decoration: none;
}

* {
  padding: 0;
  border: none;
}

body {
  font-size: 16px;
  line-height: 28px;
  background: center top/cover no-repeat #fff;
  -webkit-font-smoothing: antialiased;
}

@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
    padding: 0 15px;
  }
}

.large-container {
  max-width: 1550px;
  padding: 0 15px;
  margin: 0 auto;
}

.container-fluid,
li,
ul {
  padding: 0;
}

.more-btn {
  display: flex;
  justify-content: center;
  padding: 20px 0;
}

.auto-container {
  position: static;
  max-width: 1320px;
  padding: 0 15px;
  margin: 0 auto;
}

.boxed_wrapper,
h1,
h2,
h3,
h4,
h5,
h6,
p {
  position: relative;
}

.mainDivCategory {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 10px;
}

.categoryLink {
  cursor: pointer;
}

.categoryIcon {
  font-size: 45px;
  color: #85c226;
  max-width: 45px;
  max-height: 100px;
}

.small-container {
  max-width: 680px;
  margin: 0 auto;
}

.categoryCard {
  margin: 20px 0;
}

.boxed_wrapper {
  margin: 0 auto;
  overflow: hidden !important;
  width: 100%;
  min-width: 300px;
}

a {
  transition: 0.5s;
  -moz-transition: 0.5s;
  -webkit-transition: 0.5s;
  -ms-transition: 0.5s;
  -o-transition: 0.5s;
  cursor: pointer;
}

button,
input,
select,
textarea {
  font-family: Inter, sans-serif;
  font-weight: 400;
  font-size: 16px;
  background: 0 0;
}

::-webkit-input-placeholder {
  color: inherit;
}

::-moz-input-placeholder {
  color: inherit;
}

::-ms-input-placeholder {
  color: inherit;
}

input {
  transition: 0.5s;
}

button:focus,
input:focus,
textarea:focus {
  outline: 0;
  box-shadow: none;
  transition: 0.5s;
}

p {
  transition: 0.5s;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Jost, sans-serif;
  color: #222;
  margin: 0;
  transition: 0.5s;
}

.handle-preloader {
  align-items: center;
  -webkit-align-items: center;
  display: flex;
  display: -ms-flexbox;
  height: 100%;
  justify-content: center;
  -webkit-justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 9999999;
}

.preloader-close {
  position: fixed;
  z-index: 99999999;
  font-size: 26px;
  background: #fff;
  width: 40px;
  height: 40px;
  line-height: 36px;
  text-align: center;
  border-radius: 50%;
  cursor: pointer;
  right: 30px;
  top: 30px;
}

.theme-btn-one,
.theme-btn-two {
  overflow: hidden;
  vertical-align: middle;
  font-size: 16px;
  position: relative;
  display: inline-block;
  z-index: 1;
  font-weight: 600;
  text-transform: capitalize;
}

.handle-preloader .animation-preloader {
  position: absolute;
  z-index: 100;
}

.handle-preloader .animation-preloader .spinner {
  animation: 1s linear infinite spinner;
  border-radius: 50%;
  height: 150px;
  margin: 0 auto 45px;
  width: 150px;
  border: 3px solid #fff;
  border-top-color: rgba(255, 255, 255, 0.5);
}

.form,
.sec-pad,
.tbaleMainDiv {
  padding: 80px 0;
}

.handle-preloader .animation-preloader .txt-loading {
  text-align: center;
  user-select: none;
}

.mtualFundDiv {
  padding: 10px;
}

.handle-preloader .animation-preloader .txt-loading .letters-loading:before {
  animation: 4s infinite letters-loading;
  content: attr(data-text-preloader);
  left: 0;
  opacity: 0;
  top: 0;
  position: absolute;
}

.handle-preloader .animation-preloader .txt-loading .letters-loading {
  font-family: "DM Sans", sans-serif;
  font-weight: 500;
  letter-spacing: 15px;
  display: inline-block;
  position: relative;
  font-size: 70px;
  line-height: 70px;
  text-transform: uppercase;
}

.handle-preloader .animation-preloader .txt-loading .letters-loading:nth-child(2):before {
  animation-delay: 0.2s;
}

.handle-preloader .animation-preloader .txt-loading .letters-loading:nth-child(3):before {
  animation-delay: 0.4s;
}

.handle-preloader .animation-preloader .txt-loading .letters-loading:nth-child(4):before {
  animation-delay: 0.6s;
}

.handle-preloader .animation-preloader .txt-loading .letters-loading:nth-child(5):before {
  animation-delay: 0.8s;
}

.handle-preloader .animation-preloader .txt-loading .letters-loading:nth-child(6):before {
  animation-delay: 1s;
}

.handle-preloader .animation-preloader .txt-loading .letters-loading:nth-child(7):before {
  animation-delay: 1.2s;
}

.handle-preloader .animation-preloader .txt-loading .letters-loading:nth-child(8):before {
  animation-delay: 1.4s;
}

.handle-preloader .loader-section {
  background-color: #fff;
  height: 100%;
  position: fixed;
  top: 0;
  width: calc(50% + 1px);
}

.scroll-to-top .scroll-bar:before,
.theme-btn-one:before,
.theme-btn-two:before {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
}

.preloader .loaded .animation-preloader {
  opacity: 0;
  transition: 0.3s ease-out;
}

.footerLink:hover,
.footerLinkContact,
.handle-preloader .animation-preloader .txt-loading .letters-loading,
.handle-preloader .animation-preloader .txt-loading .letters-loading:before,
.header-style-three .header-lower .main-menu .navigation>li>a,
.header-style-three .header-lower .menu-right-content .search-box-outer,
.main-header .menu-right-content .btn-box a:hover,
.nav-style-one .owl-nav button:hover,
.sec-title.light h2,
.theme-btn-two:hover {
  color: #fff;
}

@keyframes spinner {
  to {
    transform: rotateZ(360deg);
  }
}

@keyframes letters-loading {

  0%,
  100%,
  75% {
    opacity: 0;
    transform: rotateY(-90deg);
  }

  25%,
  50% {
    opacity: 1;
    transform: rotateY(0);
  }
}

@media screen and (max-width: 767px) {
  .handle-preloader .animation-preloader .spinner {
    height: 8em;
    width: 8em;
  }
}

@media screen and (max-width: 500px) {
  .handle-preloader .animation-preloader .spinner {
    height: 7em;
    width: 7em;
  }

  .handle-preloader .animation-preloader .txt-loading .letters-loading {
    font-size: 40px;
    letter-spacing: 10px;
  }
}

.centred {
  text-align: center;
}

.main-menu,
.pull-left {
  float: left;
}

.pull-right {
  float: right;
}

.special_fonts {
  font-family: "DM Sans", sans-serif;
}

img {
  display: inline-block;
  width: 100%;
  height: auto;
  transition: 0.7s ease-in-out 0.1s;
}

.theme-btn-one {
  line-height: 24px;
  font-family: Inter, sans-serif;
  color: #fff !important;
  text-align: center;
  padding: 18px 40px;
  box-shadow: 0 30px 30px rgba(0, 0, 0, 0.1);
  border-radius: 30px;
  transition: 0.5s;
}

.bg-color-2,
.header-style-two .header-top,
.main-menu .navigation>li>.megamenu,
.theme-btn-one:hover {
  background: #241e2f;
}

.theme-btn-one:before {
  width: 100%;
  height: 100%;
  z-index: -1;
  transform: scale(0, 0);
  background: #241e2f;
  transition: 0.5s;
}

.main-header .menu-right-content .btn-box a:hover:before,
.theme-btn-one:hover:before,
.theme-btn-two:hover:before {
  transform: scale(1, 1);
}

.theme-btn-two {
  line-height: 24px;
  color: #000;
  border: 1px solid #e5e5e5;
  border-radius: 37px;
  padding: 7px 30px;
}

.theme-btn-two:before {
  width: 100%;
  height: 100%;
  transform: scale(0, 0);
  z-index: -1;
  transition: 0.5s;
}

.tbaleDiv {
  border: 1px solid;
  background-color: #85c226;
}

.tbaleCol {
  border: 1px solid #000;
  padding: 10px;
}

.pagination,
.sec-title {
  position: relative;
  display: block;
}

.pagination li {
  position: relative;
  display: inline-block;
  margin-right: 7px;
}

.header-top .social-links li:last-child,
.mr-0,
.pagination li:last-child {
  margin: 0 !important;
}

.footerLink,
.header-top .info li,
.iconDiv,
.shareIconFooter {
  margin-right: 10px;
}

.pagination li a {
  position: relative;
  display: inline-block;
  font-size: 18px;
  font-weight: 500;
  height: 60px;
  width: 60px;
  line-height: 60px;
  background: #fff;
  font-family: Poppins, sans-serif;
  border: 1px solid #e5e5e5;
  text-align: center;
  color: #222;
  z-index: 1;
  border-radius: 50%;
  transition: 0.5s;
}

.pagination li a.current,
.pagination li a:hover {
  box-shadow: 0 15px 40px 10px rgba(0, 0, 0, 0.1);
  color: #fff;
}

.subBrokage {
  font-size: 20px;
  color: #000;
}

.footerDiv {
  display: flex;
  justify-content: center;
  gap: 15px;
}

.sharIconDiv {
  display: flex;
  gap: 20px;
}

.shareIcon {
  color: #fff;
  cursor: pointer !important;
}

.shareIcon:hover {
  color: #85c226 !important;
  border: 1px solid red;
}

.copyright {
  display: flex;
  justify-content: space-between;
}

.scroll-to-top {
  position: fixed;
  right: 0;
  bottom: 100px;
  transform: rotate(90deg);
  z-index: 99;
}

.scroll-to-top .visible {
  visibility: visible !important;
  opacity: 1 !important;
}

.scroll-to-top .scroll-top-inner {
  opacity: 0;
  visibility: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  -o-transition: 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  transition: 0.4s cubic-bezier(0.4, 0, 0.2, 1);
}

.scroll-to-top .scroll-bar {
  width: 50px;
  height: 2px;
  margin-right: 10px;
  position: relative;
}

.scroll-to-top .scroll-bar:before {
  height: 100%;
  width: 100%;
  background: red;
}

.main-footer .pattern-layer .pattern-1,
.main-footer .pattern-layer .pattern-2 {
  background-size: cover;
  background-repeat: no-repeat;
  width: 373px;
  height: 100%;
  position: absolute;
}

.scroll-to-top .scroll-bar .bar-inner {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  background-color: #000;
}

.scroll-to-top .scroll-bar-text {
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  color: red;
  transition: 0.5s;
}

.scroll-to-top .scroll-bar-text:hover {
  transform: scale(1.1);
}

@-webkit-keyframes ripple {
  70% {
    -webkit-box-shadow: 0 0 0 30px rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 30px rgba(255, 255, 255, 0);
  }

  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

@keyframes ripple {
  70% {
    -webkit-box-shadow: 0 0 0 30px rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 30px rgba(255, 255, 255, 0);
  }

  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

@-webkit-keyframes diagonal-slide {

  from,
  to {
    top: 0;
    left: 0;
    animation-timing-function: linear;
  }

  20% {
    top: -5px;
    left: -5px;
    animation-timing-function: linear;
  }

  40% {
    top: 5px;
    left: -5px;
    animation-timing-function: linear;
  }

  60% {
    top: 5px;
    left: 5px;
    animation-timing-function: linear;
  }

  80% {
    top: -5px;
    left: 5px;
    animation-timing-function: linear;
  }
}

.sec-title .sub-title {
  position: relative;
  display: inline-block;
  font-size: 20px;
  line-height: 30px;
  font-weight: 500;
}

.sec-title h2 {
  position: relative;
  display: block;
  font-size: 40px;
  line-height: 50px;
  font-weight: 700;
  margin: 0;
}

.accordion-button::after,
.accordion-button:not(.collapsed)::after,
.owl-dots-none .owl-dots,
.owl-nav-none .owl-nav {
  display: none !important;
}

.main-header {
  position: relative;
  left: 0;
  top: 0;
  right: 0;
  z-index: 999;
  width: 100%;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.03);
  transition: 0.5s;
  -moz-transition: 0.5s;
  -webkit-transition: 0.5s;
  -ms-transition: 0.5s;
  -o-transition: 0.5s;
}

.search-popup,
.sticky-header {
  position: fixed;
  opacity: 0;
  left: 0;
  top: 0;
  width: 100%;
  visibility: hidden;
}

.sticky-header {
  z-index: 0;
  background-color: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  transition: 0.5s;
  -moz-transition: 0.5s;
  -webkit-transition: 0.5s;
  -ms-transition: 0.5s;
  -o-transition: 0.5s;
}

.fixed-header .sticky-header {
  z-index: 999;
  opacity: 1;
  visibility: visible;
  -ms-animation-name: fadeInDown;
  -moz-animation-name: fadeInDown;
  -op-animation-name: fadeInDown;
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  -ms-animation-duration: 500ms;
  -moz-animation-duration: 0.5s;
  -op-animation-duration: 500ms;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -ms-animation-timing-function: linear;
  -moz-animation-timing-function: linear;
  -op-animation-timing-function: linear;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -ms-animation-iteration-count: 1;
  -moz-animation-iteration-count: 1;
  -op-animation-iteration-count: 1;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}

.search-popup {
  height: 100%;
  z-index: 99999;
  overflow: auto;
  background: rgba(0, 0, 0, 0.8);
  -webkit-transform: translateY(101%);
  -ms-transform: translateY(101%);
  transform: translateY(101%);
  transition: 0.7s;
  -moz-transition: 0.7s;
  -webkit-transition: 0.7s;
  -ms-transition: 0.7s;
  -o-transition: 0.7s;
}

.search-popup.popup-visible {
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  visibility: visible;
  opacity: 1;
}

.search-popup .popup-inner {
  width: 100%;
  background: #fff;
  height: 100%;
}

.search-popup .upper-box {
  position: relative;
  padding: 70px;
  z-index: 1;
}

.search-popup .upper-box .logo-box {
  max-width: 149px;
}

.search-popup .overlay-layer {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: block;
}

.search-popup .close-search {
  position: relative;
  font-size: 22px;
  color: #141417;
  cursor: pointer;
  z-index: 5;
  top: 11px;
  transition: 0.5s;
}

.error,
.search-popup .close-search:hover {
  color: red;
}

.search-popup .search-form {
  position: relative;
  width: 100%;
  padding: 100px 0 250px;
}

.search-popup .search-form .form-group {
  position: relative;
  margin: 0;
}

.search-popup .search-form fieldset input[type="search"] {
  position: relative;
  height: 90px;
  padding: 20px 50px 20px 0;
  background: #fff;
  line-height: 30px;
  font-size: 20px;
  color: grey;
  font-family: Inter, sans-serif;
  border: none;
  font-weight: 400;
  border-radius: 0;
  border-bottom: 1px solid #e5e5e5;
}

.search-popup .search-form fieldset button[type="submit"] {
  position: absolute;
  top: 30px;
  right: 0;
  font-size: 22px;
  color: #141417;
  cursor: pointer;
  transition: 0.5s;
}

.search-popup .search-form fieldset input[type="search"]:focus {
  border-color: #141417;
}

.main-menu .navigation>li>ul>li>a,
.main-menu .navigation>li>ul>li>ul>li>a,
.mobile-menu .navigation:last-child {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.mobile-menu .navigation li,
.mobile-menu .navigation li>ul>li:first-child {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.search-popup .form-control:focus {
  box-shadow: none !important;
}

.header-top .top-inner {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 70px;
  background: #241e2f;
  border-radius: 0 0 100px 100px;
}

.header-top .info li {
  position: relative;
  display: inline-block;
  font-size: 15px;
  line-height: 24px;
  color: #fff;
  padding-left: 20px;
}

.header-top .info li i {
  position: absolute;
  left: 0;
  font-weight: 400;
}

.header-top .info li a,
.header-top .top-right .login a {
  font-weight: 500;
  display: inline-block;
  color: #fff;
  position: relative;
}

.header-top .top-right {
  position: relative;
  display: flex;
  align-items: center;
}

.header-top .top-right .login {
  position: relative;
  margin-right: 30px;
  border-right: 1px solid rgba(255, 255, 255, 0.1);
}

.blink-text {
  animation: 1s infinite blink-animation;
  color: #85c226;
}

@keyframes blink-animation {

  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }
}

.blink-link {
  color: red;
}

.header-top .top-right .login a {
  line-height: 70px;
}

.header-top .social-links li {
  position: relative;
  display: inline-block;
  margin-right: 25px;
}

.header-top .social-links li a,
.main-footer .contact-widget .info-list li a,
.main-footer .links-widget .links-list li a {
  display: inline-block;
  color: #fff;
}

.main-header .outer-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.main-header .outer-box .logo-box {
  position: relative;
  padding: 0;
}

.main-header .outer-box .logo-box .logo {
  max-width: 180px;
}

.main-footer .logo-widget .footer-logo img,
.main-header .outer-box .logo-box .logo img {
  width: 93%;
}

.dropbtn {
  color: #000;
  padding: 40px 0;
  font-size: 13px;
  border: none;
}

.contnetTitle,
.dropdown:hover .dropbtn,
.footerLink,
.footerLinkContact:hover,
.formTitle,
.titleCareer {
  color: #85c226;
}

.activiteBtn,
.contanerCard {
  padding: 20px;
}

.mainLableDiv {
  padding-bottom: 15px;
}

.titleCareer {
  font-size: 20px;
  line-height: 40px;
}

.locationDiv {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  padding: 15px 0;
}

.locationTitle {
  border: 1px solid gray;
  padding: 7px;
  border-radius: 17px;
}

.subTitleCareer {
  font-size: 20px;
}

.formTitle {
  padding-bottom: 17px;
}

.cardDiv,
.mainDivBtn {
  padding: 40px 0;
}

.shareIconFooter {
  font-size: 20px;
}

.mainDivBtn {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.footerRow {
  position: relative;
  padding: 10px 0;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content,
.dropdown-content2 {
  display: none;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  position: absolute;
  z-index: 1;
}

.dropdown-content {
  background-color: #241e2f;
  min-width: 600px;
  border-top: 2px solid #85c226;
}

.dropdown-content a {
  color: #fff;
  padding: 12px 16px;
  display: block;
}

.accordion-box .block .acc-content.current,
.dropdown:hover .dropdown-content,
.dropdown:hover .dropdown-content2 {
  display: block;
}

.tooltip {
  background-color: #85c226;
  color: #fff;
}

.tooltip-inner {
  font-size: 16px;
}

.dropdown-content2 {
  background-color: #241e2f;
  min-width: 250px;
  border-top: 3px solid #85c226;
}

.titleLink {
  font-size: 14px;
  color: #000;
}

.accordion-item:first-of-type .accordion-button {
  border-radius: 0 !important;
}

.title {
  padding: 15px 0;
  text-align: center;
  font-size: 15px;
}

.list {
  padding: 20px;
  background-color: #fff;
  border-radius: 5px;
}

.dropdowMobile {
  border-radius: 5px;
  width: 200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mobileHaderLink {
  color: #fff;
  font-size: 13px;
  display: flex;
  align-items: center;
}

.mobileHaderLi {
  padding: 10px 0;
}

.categoryImg {
  border-radius: 30px;
}

.dropdown-content2 a {
  color: #fff !important;
  padding: 12px 16px;
  display: block;
}

.main-header .menu-right-content {
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;
}

.main-header .menu-right-content .search-box-outer {
  position: relative;
  display: inline-block;
  margin-right: 15px;
  font-size: 18px;
  line-height: 16px;
  color: #222;
  cursor: pointer;
  transition: 0.5s;
}

.main-header .menu-right-content .btn-box a {
  position: relative;
  display: inline-block;
  font-size: 13px;
  line-height: 24px;
  color: #222;
  font-weight: 600;
  text-transform: capitalize;
  border: 1px solid #e5e5e5;
  border-radius: 30px;
  padding: 12px 20px;
  z-index: 1;
}

.main-header .menu-right-content .btn-box a:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  border-radius: 30px;
  transform: scale(0, 0);
  z-index: -1;
  transition: 0.5s;
}

.main-menu .navbar-collapse {
  padding: 0;
  display: block !important;
}

.main-menu .navigation>li {
  position: inherit;
  float: left;
  z-index: 2;
  margin: 0 8px;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.main-menu .navigation>li>a,
.mobile-menu .navigation li>a {
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -ms-transition: 0.5s;
  -o-transition: 0.5s;
  font-weight: 500;
}

.main-menu .navigation>li:last-child {
  margin-right: 0 !important;
}

.main-menu .navigation>li:first-child {
  margin-left: 0 !important;
}

.main-menu .navigation>li>a {
  position: relative;
  display: block;
  text-align: center;
  font-size: 14px;
  line-height: 30px;
  padding: 40px 0;
  font-family: Inter, sans-serif;
  opacity: 1;
  color: #222;
  z-index: 1;
  transition: 0.5s;
}

.main-menu .navigation>li.dropdown>a {
  padding-right: 17px;
}

.main-menu .navigation>li.dropdown>a:before {
  position: absolute;
  content: "\f107";
  font-family: "Font Awesome 5 Pro";
  top: 40px;
  right: 0;
  font-weight: 500;
  transition: 0.5s;
}

.main-menu .navigation>li>.megamenu,
.main-menu .navigation>li>ul {
  position: absolute;
  left: inherit;
  top: 100%;
  width: 230px;
  margin-top: 15px;
  padding: 10px 0;
  z-index: 100;
  background: #241e2f;
  display: none;
  opacity: 0;
  visibility: hidden;
  border-radius: 0;
  transition: 0.5s;
  -moz-transition: 0.5s;
  -webkit-transition: 0.5s;
  -ms-transition: 0.5s;
  -o-transition: 0.5s;
}

.main-menu .navigation>li>.megamenu a {
  display: inline-block !important;
}

.main-menu .navigation>li>ul.from-right,
.main-menu .navigation>li>ul>li>ul.from-right {
  left: auto;
  right: 0;
}

.main-menu .navigation>li>ul>li,
.main-menu .navigation>li>ul>li>ul>li {
  position: relative;
  width: 100%;
}

.main-menu .navigation>li>.megamenu li>a,
.main-menu .navigation>li>ul>li>a {
  position: relative;
  display: block;
  padding: 10px 30px;
  line-height: 24px;
  font-weight: 500;
  font-size: 16px;
  text-transform: capitalize;
  font-family: Inter, sans-serif;
  color: #fff;
  text-align: left;
  transition: 0.5s;
  -moz-transition: 0.5s;
  -webkit-transition: 0.5s;
  -ms-transition: 0.5s;
  -o-transition: 0.5s;
}

.main-menu .navigation>li>.megamenu li>a {
  padding-left: 0;
  padding-right: 0;
}

.main-menu .navigation>li>.megamenu h4 {
  display: block;
  font-size: 20px;
  line-height: 30px;
  color: #fff;
}

.main-menu .navigation>li>.megamenu li:last-child>a,
.main-menu .navigation>li>ul>li:last-child>a,
.main-menu .navigation>li>ul>li>ul>li:last-child,
.main-menu .navigation>li>ul>li>ul>li:last-child>a {
  border-bottom: none;
}

.main-menu .navigation>li>ul>li.dropdown>a:after {
  font-family: "Font Awesome 5 Pro";
  content: "\f105";
  position: absolute;
  right: 20px;
  top: 10px;
  display: block;
  line-height: 24px;
  font-size: 16px;
  font-weight: 800;
  text-align: center;
  z-index: 5;
}

.main-menu .navigation>li>ul>li>ul {
  position: absolute;
  left: 100%;
  top: 0;
  margin-top: 15px;
  width: 230px;
  z-index: 100;
  display: none;
  border-radius: 0;
  padding: 10px 0;
  background: #241e2f;
  transition: 0.5s;
  -moz-transition: 0.5s;
  -webkit-transition: 0.5s;
  -ms-transition: 0.5s;
  -o-transition: 0.5s;
}

.main-menu .navigation>li>ul>li>ul>li>a {
  position: relative;
  display: block;
  padding: 10px 30px;
  line-height: 24px;
  font-weight: 500;
  font-size: 16px;
  text-transform: capitalize;
  font-family: Inter, sans-serif;
  color: #fff;
  text-align: left;
  transition: 0.5s;
  -moz-transition: 0.5s;
  -webkit-transition: 0.5s;
  -ms-transition: 0.5s;
  -o-transition: 0.5s;
}

.main-menu .navigation>li>ul>li>ul>li.dropdown>a:after {
  font-family: "Font Awesome 5 Pro";
  content: "\f105";
  position: absolute;
  right: 20px;
  top: 12px;
  display: block;
  line-height: 24px;
  font-size: 16px;
  font-weight: 900;
  z-index: 5;
}

.main-menu .navigation>li.dropdown:hover>.megamenu,
.main-menu .navigation>li.dropdown:hover>ul {
  visibility: visible;
  opacity: 1;
  margin-top: 0;
  top: 100%;
}

.main-menu .navigation li>ul>li.dropdown:hover>ul {
  visibility: visible;
  opacity: 1;
  top: 0;
  margin-top: 0;
}

.main-menu .navigation li.dropdown .dropdown-btn {
  position: absolute;
  right: -32px;
  top: 66px;
  width: 34px;
  height: 30px;
  text-align: center;
  font-size: 18px;
  line-height: 26px;
  color: #3b3b3b;
  cursor: pointer;
  display: none;
  z-index: 5;
  transition: 0.5s;
}

.accordion-box .block .acc-content,
.header-style-three .menu-right-content .theme-btn-one:before,
.main-menu .navigation li.dropdown ul li.dropdown .dropdown-btn,
.mobile-menu .navigation li>ul,
.mobile-menu .navigation li>ul>li>ul,
.mobile-menu .navigation>li.dropdown>.megamenu {
  display: none;
}

.menu-area .mobile-nav-toggler,
.nav-outer .mobile-nav-toggler {
  position: relative;
  float: right;
  font-size: 40px;
  line-height: 50px;
  cursor: pointer;
  color: #3786ff;
  display: none;
}

.menu-area .mobile-nav-toggler .icon-bar {
  position: relative;
  height: 2px;
  width: 30px;
  display: block;
  margin-bottom: 5px;
  background-color: #fff;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.default-form .form-group:last-child,
.list-style-one li:last-child,
.main-footer .contact-widget .info-list li:last-child,
.main-footer .links-widget .links-list li:last-child,
.main-footer .logo-widget .text p:last-child,
.menu-area .mobile-nav-toggler .icon-bar:last-child,
.mobile-menu .contact-info ul li:last-child {
  margin-bottom: 0;
}

.sticky-header .main-menu .navigation>li>a {
  padding-top: 25px;
  padding-bottom: 25px;
}

.sticky-header .main-menu .navigation>li>a:before {
  top: 25px;
}

.sticky-header .logo-box {
  padding: 12px 0 20px;
}

.main-menu .navigation>li.dropdown>.megamenu {
  position: absolute;
  width: 100%;
  padding: 30px 50px;
  left: 0;
}

.main-menu .navigation li.dropdown .megamenu li h4 {
  margin-bottom: 10px;
}

.mobile-menu {
  position: fixed;
  right: 0;
  top: 0;
  width: 300px;
  padding-right: 30px;
  max-width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  z-index: 999999;
  transition: 0.9s;
}

.mobile-menu .navbar-collapse {
  display: block !important;
}

.mobile-menu .nav-logo {
  position: relative;
  padding: 50px 25px 100px;
  text-align: left;
}

.mobile-menu-visible {
  overflow: hidden;
}

.mobile-menu-visible .mobile-menu {
  opacity: 1;
  visibility: visible;
}

.elements-page .owl-carousel .owl-stage-outer .owl-item,
.shine {
  opacity: 0;
}

.mobile-menu .menu-backdrop {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  transition: 0.9s;
  background-color: #000;
}

.mobile-menu-visible .mobile-menu .menu-backdrop {
  opacity: 0.7;
  visibility: visible;
  right: 100%;
  -webkit-transition: 0.8s ease-out;
  -o-transition: 0.8s ease-out;
}

.mobile-menu .menu-box {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  max-height: 100%;
  overflow-y: auto;
  background: #141417;
  padding: 0;
  z-index: 5;
  opacity: 0;
  visibility: hidden;
  border-radius: 0;
  -webkit-transform: translateX(100%);
  -ms-transform: translateX(100%);
  transform: translateX(100%);
  transition: 0.9s !important;
}

.mobile-menu-visible .mobile-menu .menu-box {
  opacity: 1;
  visibility: visible;
  -webkit-transition: 0.7s;
  -o-transition: 0.7s;
  transition: 0.7s;
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}

.mobile-menu .close-btn {
  position: absolute;
  right: 25px;
  top: 10px;
  line-height: 30px;
  width: 24px;
  text-align: center;
  font-size: 16px;
  color: #fff;
  cursor: pointer;
  z-index: 10;
  -webkit-transition: 0.9s;
  -moz-transition: 0.9s;
  -ms-transition: 0.9s;
  -o-transition: 0.9s;
  transition: 0.9s;
}

.mobile-menu-visible .mobile-menu .close-btn {
  -webkit-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  transform: rotate(360deg);
}

.mobile-menu .close-btn:hover {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.mobile-menu .navigation {
  position: relative;
  display: block;
  width: 100%;
  float: none;
}

.mobile-menu .navigation li {
  position: relative;
  display: block;
}

.mobile-menu .navigation li>a {
  position: relative;
  display: block;
  line-height: 24px;
  padding: 10px 25px;
  font-size: 15px;
  color: #fff;
  text-transform: uppercase;
  transition: 0.5s;
}

.mobile-menu .navigation li.dropdown .dropdown-btn,
.mobile-menu .navigation li>a:before {
  position: absolute;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -ms-transition: 0.5s;
  -o-transition: 0.5s;
}

.mobile-menu .navigation li ul li>a {
  font-size: 16px;
  margin-left: 20px;
  text-transform: capitalize;
}

.mobile-menu .navigation li>a:before {
  content: "";
  left: 0;
  top: 0;
  height: 0;
  border-left: 5px solid #fff;
  transition: 0.5s;
}

.mobile-menu .navigation li.current>a:before {
  height: 100%;
}

.mobile-menu .navigation li.dropdown .dropdown-btn {
  right: 6px;
  top: 6px;
  width: 32px;
  height: 32px;
  text-align: center;
  font-size: 16px;
  line-height: 32px;
  color: #fff;
  background: rgba(255, 255, 255, 0.1);
  cursor: pointer;
  border-radius: 2px;
  transition: 0.5s;
  z-index: 5;
}

.header-style-two .header-top .top-right .login a,
.nice-select {
  line-height: 50px;
}

.mobile-menu .navigation li.dropdown .dropdown-btn.open {
  color: #fff;
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.mobile-menu .social-links {
  position: relative;
  padding: 0 25px;
}

.mobile-menu .social-links li {
  position: relative;
  display: inline-block;
  margin: 0 10px 10px;
}

.mobile-menu .social-links li a {
  position: relative;
  line-height: 32px;
  font-size: 16px;
  color: #fff;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -ms-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}

div#mCSB_1_container {
  top: 0 !important;
}

.mobile-menu .contact-info {
  position: relative;
  padding: 120px 30px 20px;
}

.mobile-menu .contact-info h4 {
  position: relative;
  font-size: 20px;
  color: #fff;
  font-weight: 700;
  margin-bottom: 20px;
}

.mobile-menu .contact-info ul li {
  position: relative;
  display: block;
  font-size: 15px;
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 3px;
}

.mobile-menu .contact-info ul li a {
  color: rgba(255, 255, 255, 0.8);
}

.main-header .outer-box {
  position: relative;
}

.nice-select {
  position: relative;
  background: 0 0;
  border: none;
  font-size: 16px;
  font-weight: 400;
  padding: 0 20px !important;
  color: #6e6e6e;
  height: auto;
}

.shine,
.starshine {
  position: absolute;
}

.nice-select:after {
  width: 8px;
  height: 8px;
  right: 20px;
  border-bottom: 2px solid #c4c4c4;
  border-right: 2px solid #c4c4c4;
}

.tabs-box .tab {
  position: relative;
  display: none;
  transition: 0.9s;
  -moz-transition: 0.9s;
  -webkit-transition: 0.9s;
  -ms-transition: 0.9s;
  -o-transition: 0.9s;
  transform: scale(0.9, 0.9) translateY(0);
}

.tabs-box .tab.active-tab {
  display: block;
  transform: scale(1) translateY(0);
}

.starshine {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  overflow: hidden;
}

.shine {
  display: block;
  background-repeat: no-repeat;
  background-position: center;
  width: 893px;
  height: 893px;
  -webkit-animation: 6s linear infinite glitter;
  animation: 6s linear infinite glitter;
  text-indent: -9999px;
}

.shine.shine-1 {
  left: -350px;
  bottom: -350px;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.shine.shine-2 {
  top: -400px;
  left: 400px;
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
}

@-webkit-keyframes glitter {

  0%,
  100% {
    -webkit-transform: scale(0.3) rotate(0);
    opacity: 0;
  }

  25% {
    -webkit-transform: scale(1) rotate(360deg);
    opacity: 1;
  }

  50% {
    -webkit-transform: scale(0.3) rotate(720deg);
    opacity: 0;
  }
}

@keyframes glitter {

  0%,
  100% {
    -webkit-transform: scale(0.3) rotate(0);
    transform: scale(0.3) rotate(0);
    opacity: 0;
  }

  25% {
    -webkit-transform: scale(1) rotate(360deg);
    transform: scale(1) rotate(360deg);
    opacity: 1;
  }

  50% {
    -webkit-transform: scale(0.3) rotate(720deg);
    transform: scale(0.3) rotate(720deg);
    opacity: 0;
  }
}

@-moz-keyframes glitter {

  0%,
  100% {
    -moz-transform: scale(0.3) rotate(0);
    opacity: 0;
  }

  25% {
    -moz-transform: scale(1) rotate(360deg);
    opacity: 1;
  }

  50% {
    -moz-transform: scale(0.3) rotate(720deg);
    opacity: 0;
  }
}

.bg-color-1,
.main-footer.alternat-2 {
  background: #f2f2f2;
}

@-webkit-keyframes shine {
  100% {
    left: 125%;
  }
}

@keyframes shine {
  100% {
    left: 125%;
  }
}

@-webkit-keyframes ripple {
  70% {
    -webkit-box-shadow: 0 0 0 40px rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 40px rgba(255, 255, 255, 0);
  }

  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

@keyframes ripple {
  70% {
    -webkit-box-shadow: 0 0 0 40px rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 40px rgba(255, 255, 255, 0);
  }

  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

.nav-style-one .owl-nav button {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 60px;
  line-height: 66px;
  background: #fff;
  font-size: 18px;
  color: #222;
  border-radius: 50%;
  text-align: center;
  cursor: pointer;
  transition: 0.5s;
}

.default-form .form-group {
  position: relative;
  margin-bottom: 20px;
}

.default-form .form-group .nice-select,
.default-form .form-group input[type="email"],
.default-form .form-group input[type="number"],
.default-form .form-group input[type="password"],
.default-form .form-group input[type="text"],
.default-form .form-group textarea {
  position: relative;
  display: block;
  width: 100%;
  height: 50px;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  font-size: 16px;
  color: #6e6e6e;
  padding: 10px 20px;
  text-align: left;
  transition: 0.5s;
}

.default-form .form-group textarea {
  resize: none;
}

.main-footer {
  position: relative;
  background: #1d1826;
}

.main-footer .widget-section {
  position: relative;
  display: block;
  overflow: hidden;
  padding: 50px 0 20px;
}

.main-footer .logo-widget {
  position: relative;
  margin-top: -14px;
}

.main-footer .logo-widget .footer-logo {
  position: relative;
  max-width: 184px;
  margin-bottom: 32px;
}

.burgerIcon {
  color: #85c226;
  font-size: 30px;
}

.main-footer .logo-widget .text p {
  color: #fff;
  margin-bottom: 26px;
}

:root {
  --navBg: #060b26;
}

.navbar {
  background-color: #85c226;
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 8px;
}

.menu-bars {
  font-size: 2rem;
  background: 0 0;
}

.nav-menu,
.navbar-toggle {
  background-color: var(--navBg);
  display: flex;
}

.nav-menu {
  width: 300px;
  height: 100vh;
  justify-content: center;
  position: fixed;
  top: 0;
  left: -100%;
  transition: 850ms;
}

.nav-menu.active {
  left: 0;
  transition: 350ms;
  overflow-y: auto;
}

.nav-text {
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 8px 0 8px 16px;
  height: 60px;
}

.nav-text a {
  color: #f5f5f5;
  font-size: 18px;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 4px;
}

.nav-text a:hover {
  background-color: #1a83ff;
}

.nav-menu-items {
  width: 100%;
  padding: 0;
}

.navbar-toggle {
  width: 100%;
  height: 80px;
  justify-content: end;
  align-items: center;
}

.main-footer .widget-title {
  position: relative;
  display: block;
  margin-bottom: 28px;
}

.main-footer .widget-title h3 {
  font-size: 24px;
  line-height: 28px;
  color: #fff;
  font-weight: 600;
}

.main-footer .links-widget .links-list li {
  position: relative;
  display: block;
  margin-bottom: 14px;
}

.main-footer .contact-widget .widget-content p {
  display: block;
  color: #fff;
  margin-bottom: 22px;
}

.main-footer .contact-widget .info-list li {
  position: relative;
  display: block;
  font-size: 16px;
  color: #fff;
  padding-left: 28px;
  margin-bottom: 14px;
}

.main-footer .contact-widget .info-list li i {
  position: absolute;
  left: 0;
  top: 1px;
  font-size: 14px;
}

.main-footer .contact-widget .info-list li:first-child i {
  font-size: 21px;
  top: 1px;
}

.main-footer .contact-widget .info-list li:last-child i {
  font-size: 18px;
  top: 1px;
}

.main-footer .pattern-layer .pattern-1 {
  left: 0;
  top: 0;
}

.main-footer .pattern-layer .pattern-2 {
  top: 0;
  right: 0;
}

.footer-bottom {
  position: relative;
  display: block;
  background: #191521;
  padding: 31px 0;
}

.footer-bottom a,
.footer-bottom p {
  color: #fff;
  font-weight: 500;
}

.header-style-two.main-header .menu-right-content .btn-box a {
  box-shadow: none;
}

.header-style-two.main-header .menu-right-content .btn-box a:hover {
  background: #241e2f;
  border-color: #241e2f;
}

.parallax-bg {
  position: absolute;
  left: 0;
  top: -30%;
  width: 100%;
  height: 150%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  z-index: -1;
}

.owl-dots-one .owl-dots .owl-dot span {
  position: relative;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 2px solid #fff;
  margin: 0 5px;
  cursor: pointer;
  transition: 0.5s;
}

.main-footer.alternat-2 .widget-section {
  padding: 265px 0 95px;
}

.main-footer.alternat-2 .contact-widget .info-list li,
.main-footer.alternat-2 .contact-widget .info-list li a,
.main-footer.alternat-2 .contact-widget .widget-content p,
.main-footer.alternat-2 .footer-bottom p,
.main-footer.alternat-2 .footer-bottom p a,
.main-footer.alternat-2 .links-widget .links-list li a,
.main-footer.alternat-2 .logo-widget .text p {
  color: #6e6e6e;
}

.main-footer.alternat-2 .widget-title h3 {
  color: #222;
}

.main-footer.alternat-2 .pattern-layer .pattern-2 {
  width: 625px;
}

.main-footer.alternat-2 .footer-bottom {
  background: #ededed;
}

.header-style-three .header-lower {
  position: absolute;
  left: 70px;
  top: 50px;
  width: calc(100% - 140px);
  background: rgba(255, 255, 255, 0.07);
  backdrop-filter: blur(7.5px);
  border-radius: 10px;
}

.header-style-three .outer-container {
  position: relative;
  padding: 0 50px;
}

.header-style-three .menu-right-content .cart-box a {
  position: relative;
  display: inline-block;
  font-size: 18px;
  color: #fff;
}

.header-style-three .menu-right-content .cart-box {
  margin-right: 30px;
}

.header-style-three .menu-right-content .theme-btn-one:hover {
  background: #17141f !important;
}

.list-style-one li {
  position: relative;
  display: block;
  font-size: 15px;
  line-height: 25px;
  color: #222;
  font-weight: 500;
  padding-left: 27px;
  margin-bottom: 12px;
}

.faq-page-section .accordion-box .accordion {
  background: #fff;
  box-shadow: 0 10px 50px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.bootstrap-touchspin .input-group-btn-vertical>.btn.bootstrap-touchspin-down {
  position: absolute;
  background: 0 0;
  padding: 0;
  right: -18px;
  top: 4px;
  border-radius: 0;
  border: none !important;
  z-index: 1;
}

.bootstrap-touchspin .input-group-btn-vertical>.btn.bootstrap-touchspin-up {
  position: absolute;
  background: 0 0;
  padding: 0;
  right: -18px;
  top: 17px;
  border: none !important;
  margin-top: 0;
  border-radius: 0;
  z-index: 1;
}

.bootstrap-touchspin .glyphicon-chevron-up:before {
  content: "\f107";
  font-size: 18px;
  font-style: normal;
  color: #b3b3b3;
  font-family: "Font Awesome 5 pro";
  font-weight: 400;
  transition: 0.5s;
}

.bootstrap-touchspin .glyphicon-chevron-down:before {
  content: "\f106";
  font-size: 18px;
  font-style: normal;
  color: #b3b3b3;
  font-weight: 400;
  font-family: "Font Awesome 5 pro";
  transition: 0.5s;
}

.bootstrap-touchspin .input-group-btn-vertical {
  position: absolute;
  width: 100%;
}

.bootstrap-touchspin .input-group-btn-vertical i {
  top: 0;
  left: 7px;
  font-size: inherit !important;
}

.bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-down {
  margin-top: 0;
}

.default-form input[type="email"],
.default-form input[type="password"],
.default-form input[type="tel"],
.default-form input[type="text"],
.default-form textarea {
  position: relative;
  width: 100%;
  height: 50px;
  background: 0 0;
  font-size: 15px;
  color: #6e6e6e;
  padding: 15px 20px;
  border-radius: 30px;
  border: 1px solid #e5e5e5;
  transition: 0.5s;
}

.elements-page .owl-carousel .owl-stage-outer {
  overflow: visible;
}

.elements-page .owl-carousel .owl-stage-outer .owl-item.active {
  opacity: 1;
}

.chooseus-style-two .bg-layer {
  background-position: right center;
}

.team-details .progress-box .count-text {
  right: 0;
}