.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999999999;
}
.popup-content {
  background: #efe4b0;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}
.croseIcon {
  text-align: left;
  font-size: 30px;
  color: red;
}
.iconDiv {
  display: flex;
  justify-content: flex-end;
}
