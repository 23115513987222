@media only screen and (max-width: 1299px) {
  .feature-block-three .inner-box,
  .service-block-one .inner-box,
  .testimonial-block-one .inner-box {
    background: #fff;
    border-radius: 10px;
  }
  .feature-block-three .inner-box .block-shape,
  .service-block-one .inner-box .shape,
  .testimonial-block-one .inner-box .shape {
    display: none;
  }
  .service-block-one .inner-box {
    box-shadow: 0 20px 80px rgb(0 0 0 / 5%);
    padding: 40px 55px 34px;
  }
  .service-block-one .inner-box .overlay-content {
    background: #241e2f;
    top: 0 !important;
    border-radius: 10px;
    padding-top: 50px;
  }
  .chooseus-section .outer-box {
    padding: 100px 30px;
    display: block;
  }
  .chooseus-section .outer-box h2 {
    margin-bottom: 15px;
  }
  .feature-block-three .inner-box {
    box-shadow: 0 20px 80px rgb(0 0 0 / 10%);
    padding: 0 30px 45px !important;
  }
}
@media only screen and (max-width: 1200px) {
  .icon-10,
  .lightbox-image {
    line-height: 95px !important;
  }
  .header-top .top-right .login a,
  .sec-title h2 {
    line-height: 30px !important;
  }
  .categoryTitle,
  .feature-block-one .inner-box .lower-content h3 {
    font-size: 17px;
  }
  .main-header.style-one .outer-container:before,
  .main-menu,
  .sticky-header {
    display: none !important;
  }
  .menu-area .mobile-nav-toggler {
    display: block;
    padding: 10px;
  }
  .banner-section .banner-carousel .owl-nav,
  .megamenu ul li:first-child,
  .testimonial-section .nav-style-one .owl-nav {
    display: none;
  }
  .main-header .header-lower {
    padding: 15px 0;
  }
  .header-top .top-inner {
    display: block;
    text-align: center;
    padding: 15px 30px;
  }
  .header-top .top-right {
    justify-content: center;
  }
  .header-top .top-right .login {
    padding-right: 0;
    border-right: none;
  }
  .blog-sidebar,
  .career-section .content-box,
  .career-sidebar,
  .content_block_five .content-box,
  .content_block_one .content-box,
  .image_block_one .image-box,
  .rtl .content_block_one .content-box,
  .service-sidebar,
  .team-details .lower-content .education-inner,
  .team-details-content .content-box,
  .team-details-content .image-box {
    margin: 0;
  }
  .feature-style-two .sec-title {
    padding-right: 0;
    margin-bottom: 40px;
  }
  .feature-block-two .inner-box,
  .pricing-block-one .pricing-table {
    padding-left: 30px;
    padding-right: 30px;
  }
  .service-block-two .inner-box .lower-content {
    margin-left: 50px;
  }
  .expertise-section .image-layer {
    left: 0;
  }
  .video-btn {
    left: 140px !important;
  }
  .lightbox-image {
    width: 107px !important;
    height: 107px !important;
  }
  .testimonial-block-two .inner-box {
    padding-right: 30px;
  }
  .contact-style-two .social-links li {
    margin-bottom: 10px !important;
  }
  .sec-title h2 {
    font-size: 23px !important;
  }
  .rtl .header-top .top-right .login {
    padding-left: 0;
    border-left: none;
  }
  .categoryTitle {
    font-weight: 600;
  }
  .service-block-three .inner-box h3 {
    font-size: 18px !important;
  }
  .main-footer .widget-title h3,
  .news-block-one .inner-box .lower-content h3 {
    font-size: 17px !important;
  }
  .copyright {
    gap: 20px;
  }
  .service-block-one .inner-box h3 {
    font-size: 16px !important;
  }
  .news-block-one .inner-box .lower-content p {
    font-size: 15px !important;
  }
  .footerLinkContact {
    font-size: 12px;
  }
}
@media only screen and (max-width: 991px) {
  .feature-block-one .inner-box,
  .feature-block-two .inner-box,
  .funfact-block-one .inner-box,
  .image_block_one .image-box,
  .news-block-one .inner-box,
  .news-block-two .inner-box,
  .pricing-block-one .pricing-table,
  .team-block-one .inner-box,
  .team-details-content .image-box {
    margin-bottom: 30px;
  }
  .feature-section,
  .feature-style-two,
  .news-section,
  .news-style-two,
  .pricing-section,
  .team-section {
    padding-bottom: 120px;
  }
  .chooseus-section .outer-box {
    position: relative;
    width: 100%;
  }
  .banner-style-two .owl-nav,
  .banner-style-two.alternat-2 .banner-image,
  .chooseus-section .bg-layer,
  .main-footer .pattern-layer .pattern-1,
  .main-footer .pattern-layer .pattern-2,
  .working-block-one .inner-box .shape {
    display: none;
  }
  .testimonial-section .sec-title {
    max-width: 100%;
    margin-bottom: 40px;
  }
  .working-process-section .inner-container {
    display: block;
    text-align: center;
  }
  .working-block-one .inner-box {
    margin-bottom: 70px;
  }
  .clients-section .inner-box .clients-logo:last-child,
  .shop-details .shop-sidebar,
  .working-block-one:last-child .inner-box {
    margin-bottom: 0;
  }
  .working-block-one {
    max-width: 320px;
    margin: 0 auto;
  }
  .funfact-section .inner-container {
    padding-bottom: 30px;
  }
  .content_block_three .content-box {
    margin-bottom: 30px;
    margin-right: 0;
  }
  .content_block_four .content-box,
  .shop-details-content .content-box {
    margin: 0;
  }
  .main-footer .footer-widget {
    margin: 0 0 30px !important;
  }
  .main-footer .widget-section {
    padding-bottom: 55px;
  }
  .contact-style-two .content-box,
  .image_block_two .image-box {
    margin-right: 0;
    margin-bottom: 30px;
  }
  .subscribe-section .inner-container {
    padding-left: 30px;
    padding-right: 30px;
  }
  .main-footer.alternat-2 .widget-section {
    padding-bottom: 65px;
  }
  .header-style-three .header-lower {
    width: 100%;
    top: 0;
    left: 0;
  }
  .header-style-three .outer-container {
    padding: 0 30px;
  }
  .feature-block-three .inner-box .icon-box {
    margin-top: 0;
  }
  .feature-block-three .inner-box {
    padding-top: 50px !important;
    margin-bottom: 30px;
  }
  .image_block_three .image-box {
    margin-left: 0;
    margin-top: 30px;
  }
  .clients-section .inner-box {
    display: block;
  }
  .clients-section .inner-box .clients-logo {
    margin-bottom: 15px;
  }
  .content_block_six .content-box {
    margin-left: 0;
  }
  .service-sidebar {
    margin-bottom: 50px;
  }
  .shop-details-content .image-box,
  .team-details .lower-content .education-inner,
  .team-details .lower-content .skills-box {
    margin: 0 0 30px;
  }
  .team-details {
    padding-bottom: 115px;
  }
  .blog-sidebar,
  .career-sidebar,
  .portfolio-sidebar {
    margin-top: 40px;
  }
  .shop-sidebar {
    margin: 0 0 40px;
  }
  .checkout-section .order-info {
    margin-top: 50px;
  }
}
@media screen and (min-width: 1199px) {
  .mainDivMobileHader {
    display: none;
  }
}
@media only screen and (max-width: 820px) {
  .pt_150 {
    padding-top: 80px !important;
  }
  .pb_150 {
    padding-bottom: 80px !important;
  }
  .about-section {
    padding: 40px 0 !important;
  }
  .service-block-one .inner-box,
  .service-block-one .inner-box .overlay-content {
    padding: 20px 55px 34px !important;
  }
}
@media only screen and (max-width: 768px) {
  .main-menu .navigation > li > .megamenu,
  .main-menu .navigation > li > ul,
  .main-menu .navigation > li > ul > li > ul {
    display: block !important;
    visibility: hidden;
    opacity: 0;
  }
  .header-top .top-right .login {
    padding-right: 0 !important;
  }
  .header-top .social-links li {
    margin-right: 11px !important;
  }
  .mt_60 {
    margin-top: 4px !important;
  }
  .whatsappDiv {
    bottom: 115px;
  }
  .video-btn {
    left: 280px !important;
  }
  .service-block-one .inner-box {
    height: 280px !important;
  }
  .message-btn {
    display: flex;
    justify-content: center;
  }
  .banner-carousel .content-box h2,
  .page-title h1 {
    font-size: 40px;
    line-height: 50px;
  }
  .banner-carousel .slide-item,
  .page-title {
    padding: 100px 0;
  }
  .sec-pad {
    padding: 50px 0 70px !important;
  }
  .sec-title h2,
  .sidebar-page-container .news-block-one .inner-box .lower-content h2,
  .subscribe-section h2 {
    font-size: 30px;
    line-height: 40px;
  }
  .feature-section,
  .news-style-two,
  .pricing-section,
  .service-section,
  .team-section {
    padding-bottom: 40px;
  }
  .about-section {
    padding: 40px 0 !important;
  }
  .service-section .more-btn {
    margin: 0;
  }
  .career-section,
  .chooseus-section .content-box,
  .projects-section,
  .shop-section,
  .testimonial-section,
  .testimonial-style-two {
    padding: 63px 0 70px;
  }
  .chooseus-section .outer-box {
    padding-top: 63px;
    padding-bottom: 70px;
  }
  .banner-style-two.alternat-2 .owl-dots,
  .header-style-three .menu-right-content .btn-box,
  .projects-section .owl-theme .owl-dots,
  .testimonial-style-two .owl-dots {
    display: none;
  }
  .expertise-section {
    padding: 180px 0 70px;
  }
  .news-section {
    padding-bottom: 10px;
  }
  .banner-style-two .banner-carousel .slide-item {
    padding: 92px 0 100px;
  }
  .feature-style-two,
  .projects-section.project-page-one,
  .service-style-two,
  .team-page-section,
  .testimonial-page-section {
    padding: 63px 0 40px;
  }
  .cta-section {
    padding: 65px 0;
  }
  .faq-page-section .content-column,
  .service-details-content .content-two .text-box,
  .subscribe-section .form-inner .form-group {
    margin-bottom: 30px;
  }
  .faq-page-section .content-column:last-child,
  .service-details-content
    .content-three
    .single-column:last-child
    .single-item,
  .subscribe-section .form-inner .form-group:last-child {
    margin-bottom: 0;
  }
  .banner-style-two.alternat-2 .banner-carousel .slide-item {
    padding: 250px 0 120px;
  }
  .feature-style-three {
    padding-top: 63px;
  }
  .about-style-three {
    padding-top: 40px;
    padding-bottom: 70px;
  }
  .service-style-three {
    padding: 63px 0 310px;
  }
  .expertise-section.alternat-2 {
    padding-top: 40px;
  }
  .checkout-section,
  .news-style-two.blog-grid,
  .portfolio-details,
  .service-details,
  .shop-details,
  .sidebar-page-container {
    padding: 70px 0;
  }
  .service-details-content .content-four .download-list li button {
    position: relative;
    top: 0;
    right: 0;
    margin-top: 10px;
  }
  .team-details {
    padding: 70px 0 35px;
  }
  .projects-style-three {
    padding: 63px 0 10px;
  }
  .portfolio-details .nav-btn-box {
    margin-top: 40px;
  }
  .cart-section .othre-content .coupon-box button,
  .error-section .inner-box .error-image img {
    width: 100%;
  }
  .cart-section {
    padding: 35px 0 70px;
  }
  .cart-section .othre-content {
    display: block;
  }
  .cart-section .othre-content .theme-btn-two {
    width: 100%;
    margin-top: 15px;
  }
  .cart-section .othre-content .coupon-box {
    display: block;
    min-width: 100%;
  }
  .cart-section .othre-content .coupon-box input[type="text"] {
    margin-right: 0;
    max-width: 100%;
    margin-bottom: 15px;
  }
  .service-details-content .content-three .single-item {
    margin-bottom: 80px;
  }
  .header-top .info li {
    margin-right: 22px !important;
  }
  .header-top .top-inner {
    flex-direction: column !important;
    padding: 10px 24px !important;
    gap: 13px !important;
  }
  .service-block-one .inner-box .overlay-content {
    padding: 40px 55px 34px !important;
  }
  .shareLinkHader {
    display: flex !important;
  }
  .header-top {
    display: none !important;
  }
}
@media only screen and (max-width: 599px) {
  .banner-style-two.alternat-2 .pattern-layer .pattern-2,
  .header-style-two .menu-right-content,
  .main-header .menu-right-content .btn-box {
    display: none;
  }
  .search-popup .upper-box {
    padding: 70px 30px;
  }
  .image_block_two .image-box .image-content {
    padding-right: 30px;
  }
  .image_block_two .image-box .image-content h3 {
    font-size: 24px;
  }
  .message-btn {
    display: flex;
    justify-content: center;
  }
  .subscribe-section .form-inner form {
    padding-right: 0;
  }
  .linkContact {
    font-size: 12px !important;
  }
  .subscribe-section .form-inner .message-btn {
    position: relative;
    margin-top: 15px;
  }
  .subscribe-section .form-inner .form-group {
    margin-bottom: 15px;
  }
  .subscribe-section .form-inner .message-btn .theme-btn-one {
    width: 100%;
  }
  .portfolio-details .image-box {
    margin-bottom: 40px;
  }
}
@media only screen and (max-width: 499px) {
  .feature-section .pattern-layer .pattern-1,
  .mobile-menu {
    width: 100%;
  }
  .header-top {
    display: none !important;
  }
  .sec-title h2 {
    line-height: 36px;
    font-size: 26px !important;
  }
  .image_block_one .image-box .video-btn a {
    width: 121px;
    height: 121px;
    line-height: 112px;
  }
  .page-title h1 {
    font-size: 30px !important;
  }
  .theme-btn-one {
    font-size: 11px !important;
    padding: 13px 15px !important;
  }
  .footerLinkContact {
    font-size: 17px !important;
  }
  .sidebar-page-container .news-block-one .inner-box .lower-content h2 {
    font-size: 23px !important;
    line-height: 38px !important;
  }
  .blog-details-content .news-block-one .inner-box blockquote h4 {
    font-size: 17px !important;
    line-height: 25px !important;
  }
  .header-top .top-inner {
    padding: 15px;
    flex-direction: column;
  }
  .main-header .menu-right-content {
    display: none;
  }
  .banner-carousel .content-box .content-inner,
  .blog-details-content .author-box,
  .blog-details-content .comment-form-area,
  .blog-sidebar .sidebar-widget,
  .contact-section .form-inner,
  .team-details-content {
    padding-left: 30px;
    padding-right: 30px;
  }
  .feature-block-one .inner-box {
    padding: 0 30px;
  }
  .image_block_one .image-box {
    padding: 0;
  }
  .image_block_one .image-box .image-2 {
    position: relative;
    margin-top: 30px;
  }
  .image_block_one .image-box .video-btn {
    left: 142px !important;
    bottom: 242px !important;
  }
  .content_block_four .content-box,
  .service-block-one .inner-box,
  .service-block-one .inner-box .overlay-content,
  .service-sidebar .category-widget,
  .testimonial-block-one .inner-box {
    padding: 34px 55px !important;
  }
  .btn-box {
    display: flex;
    justify-content: center;
  }
  .about-section {
    padding: 30px 0;
  }
  .mainDivCaresol {
    padding: 0 !important;
  }
  .image_block_two .image-box .image-content {
    position: relative;
    left: 0;
    bottom: 0;
    margin-top: 30px;
    background: rgba(0, 0, 0, 0.2);
    width: 100%;
  }
  .image_block_three .image-box,
  .service-block-three .inner-box,
  .shop-details .product-discription .customer-inner .comment-box,
  .testimonial-block-two {
    padding-left: 0;
  }
  .testimonial-block-two .thumb-box {
    position: relative;
    top: 0;
    margin-bottom: 20px;
  }
  .testimonial-block-two .inner-box {
    padding-left: 30px;
  }
  .working-block-one .inner-box .count-text {
    right: 0;
  }
  .news-block-two .inner-box .lower-content {
    margin: 0 15px;
  }
  .subscribe-section .form-inner .message-btn .theme-btn-one {
    padding: 13px 30px;
  }
  .image_block_three .image-box .image-text {
    position: relative;
    left: 0;
    bottom: 0;
    transform: rotate(0);
    margin-top: 30px;
  }
  .service-block-three .inner-box .icon-box {
    position: relative;
    top: 0;
    margin-bottom: 15px;
  }
  .video-section .inner-container {
    padding: 150px 0;
  }
  .portfolio-details .nav-btn-box {
    display: block;
    text-align: center;
  }
  .portfolio-details .nav-btn-box .icon-box {
    margin: 15px 0;
  }
  .shop-details .product-discription .tab-btn-box .tab-btns li {
    padding-left: 15px;
    padding-right: 15px;
    font-size: 16px;
  }
  .shop-details .product-discription .customer-inner .comment-thumb {
    position: relative;
    margin-bottom: 15px;
  }
  .shop-details-content .addto-cart-box li {
    margin-bottom: 10px;
    margin-right: 10px;
  }
  .checkout-section .order-info .order-list li .single-box {
    padding-top: 10px;
  }
  .checkout-section .order-info .order-list li .single-box p {
    font-size: 16px;
  }
  .sidebar-page-container .news-block-one .inner-box:before {
    height: 100%;
  }
  .sidebar-page-container .news-block-one .inner-box {
    padding: 0 20px;
  }
  .blog-details-content .news-block-one .inner-box blockquote {
    padding-left: 20px;
    padding-right: 20px;
  }
  .blog-details-content .news-block-one .inner-box .tags-list li {
    margin-bottom: 10px;
  }
  .blog-details-content .news-block-one .inner-box .tags-list li:last-child {
    margin-bottom: 0;
  }
  .blog-details-content .author-box .author-thumb {
    position: relative;
    left: 0;
    top: 0;
    margin-bottom: 15px;
  }
  .whatsappDiv {
    bottom: 143px;
  }
}
