.about-section {
  padding: 80px 0;
}
.image_block_one .image-box {
  display: block;
  padding-bottom: 145px;
}
.image_block_one .image-box .image-2 {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.image_block_one .image-box .image {
  border-radius: 10px;
}
.image_block_one .image-box .image img,
.image_block_two .image-box .image img {
  width: 100%;
  border-radius: 10px;
}
.image_block_one .image-box .image-1 {
  position: relative;
  overflow: hidden;
}
.image_block_one .image-box .image-1:before {
  position: absolute;
  top: 0;
  left: -80%;
  display: block;
  content: "";
  width: 50%;
  height: 100%;
  background: -o-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0,
    rgba(255, 255, 255, 0.3) 100%
  );
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(rgba(255, 255, 255, 0)),
    to(rgba(255, 255, 255, 0.3))
  );
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0,
    rgba(255, 255, 255, 0.3) 100%
  );
  -webkit-transform: skewX(-25deg);
  -ms-transform: skewX(-25deg);
  transform: skewX(-25deg);
  z-index: 1;
}
.image_block_one .image-box:hover .image-1:before {
  -webkit-animation: 1s shine;
  animation: 1s shine;
}
.image_block_one .image-box .video-btn {
  position: absolute;
  left: 160px;
  bottom: 190px;
}
.image_block_one .image-box .video-btn a {
  position: relative;
  display: inline-block;
  width: 150px;
  height: 150px;
  line-height: 140px;
  font-size: 77px;
  color: #fff;
  text-align: center;
  border-radius: 50%;
  z-index: 1;
}
.image_block_one .image-box .video-btn a:after,
.image_block_one .image-box .video-btn a:before {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: 0 0;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  content: "";
  position: absolute;
  -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5);
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5);
  -webkit-animation: 3s infinite ripple;
  animation: 3s infinite ripple;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.image_block_one .image-box .video-btn a:after {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}
.content_block_one .content-box {
  display: block;
  margin-top: -7px;
}
.content_block_one .content-box .inner-box .single-item {
  position: relative;
  display: block;
  padding: 0 0 0 80px;
  margin-bottom: 25px;
  max-width: 360px;
}
.content_block_five .content-box .inner-box .single-item:last-child,
.content_block_one .content-box .inner-box .single-item:last-child {
  margin-bottom: 0;
}
.content_block_one .content-box .inner-box .single-item .icon-box {
  position: absolute;
  display: inline-block;
  left: 0;
  top: 4px;
  width: 60px;
  height: 60px;
  line-height: 62px;
  text-align: center;
  font-size: 26px;
  background: rgb(133 194 38 / 16%);
  border-radius: 50%;
}
.content_block_one .content-box .inner-box .single-item h3 {
  display: block;
  font-size: 22px;
  line-height: 30px;
  font-weight: 600;
  margin-bottom: 8px;
}
.about-style-two {
  position: relative;
}
.image_block_two .image-box {
  position: relative;
  display: block;
}
.image_block_two .image-box .image {
  position: relative;
  display: block;
  overflow: hidden;
  border-radius: 10px;
}
.image_block_two .image-box .image:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  border-radius: 10px;
  background: linear-gradient(
    180deg,
    rgba(36, 30, 47, 0) 37.66%,
    rgba(36, 30, 47, 0.8) 100%
  );
  z-index: 1;
}
.image_block_two .image-box .image-content {
  position: absolute;
  left: 60px;
  bottom: 30px;
  width: calc(100% - 120px);
  background: rgba(255, 255, 255, 0.2);
  border: 2px solid rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(7.5px);
  border-radius: 10px;
  padding: 32px 100px 36px 150px;
  min-height: 140px;
  z-index: 2;
}
.image_block_two .image-box .image-content h2 {
  position: absolute;
  display: inline-block;
  left: 18px;
  top: 18px;
  width: 100px;
  height: 100px;
  line-height: 100px;
  border-radius: 50%;
  background: #fff;
  text-align: center;
  font-size: 40px;
  font-weight: 700;
}
.image_block_two .image-box .image-content h3 {
  position: relative;
  display: block;
  font-size: 28px;
  line-height: 34px;
  color: #fff;
  font-weight: 700;
}
.content_block_five .content-box {
  position: relative;
  display: block;
  margin-top: -8px;
}
.content_block_five .content-box .text p {
  color: #222;
}
.content_block_five .content-box .inner-box .single-item {
  position: relative;
  display: block;
  padding-left: 80px;
  max-width: 360px;
  margin-bottom: 25px;
}
.content_block_five .content-box .inner-box .single-item .icon-box {
  position: absolute;
  left: 0;
  top: 0;
  display: inline-block;
  width: 60px;
  height: 60px;
  line-height: 60px;
  font-size: 24px;
  text-align: center;
  border-radius: 50%;
  background: rgba(222, 44, 44, 0.15);
}
.content_block_five .content-box .inner-box .single-item h3 {
  display: block;
  font-size: 22px;
  line-height: 32px;
  font-weight: 600;
  margin-bottom: 8px;
}
.about-style-three {
  padding: 150px 0;
}
.about-style-three .content_block_one .content-box .text p:first-child {
  color: #222;
  margin-bottom: 26px;
  padding-left: 20px;
}
.about-style-three .content_block_one .content-box .text p:first-child:before {
  position: absolute;
  content: "";
  width: 3px;
  height: 43px;
  left: 0;
  top: 8px;
}
.image_block_three .image-box {
  display: block;
}
.image_block_three .image-box .image {
  position: relative;
  display: block;
  border-radius: 10px;
  overflow: hidden;
}
.image_block_three .image-box img {
  width: 100%;
  border-radius: 10px;
  transition: 0.5s;
}
.image_block_three .image-box .image:hover img {
  transform: scale(1.05);
}
.image_block_three .image-box .image-text {
  position: absolute;
  left: -73px;
  bottom: 120px;
  padding: 44px 59px;
  border-radius: 10px;
  transform: rotate(-90deg);
}
.image_block_three .image-box .image-text h3 {
  font-size: 26px;
  line-height: 36px;
  font-weight: 700;
  color: #fff;
}
