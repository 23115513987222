.mainDivStock {
  border: 1px solid;
  padding: 15px;
  border-radius: 5px;
  background-color: #85c226;
  color: #fff;
}
.stockFilterTitle,
.stockLink {
  color: #fff;
  line-height: 45px;
}
.tbaleLink {
  color: #000;
}
