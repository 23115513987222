.team-block-one .inner-box,
.team-block-one .inner-box .image-box,
.team-block-one .inner-box .image-box .image {
  position: relative;
  overflow: hidden;
  display: block;
}
.team-section {
  position: relative;
}
.team-block-one .inner-box {
  background: #f1f1f1;
  border-radius: 10px;
  transition: 0.5s;
}
.team-block-one .inner-box:hover {
  background: #fff;
  box-shadow: 0 0 120px rgba(0, 0, 0, 0.1);
}
.team-block-one .inner-box .image-box .image {
  background: #000;
}
.team-block-one .inner-box .image-box .image img {
  width: 100%;
  transition: 0.5s;
}
.team-block-one .inner-box:hover .image-box .image img {
  transform: scale(1.05);
  opacity: 0.6;
}
.team-block-one .inner-box .image-box .social-links {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  opacity: 0;
  transition: 0.5s;
}
.team-block-one .inner-box:hover .image-box .social-links {
  right: 20px;
  opacity: 1;
}
.team-block-one .inner-box .image-box .social-links li {
  position: relative;
  display: block;
  margin-bottom: 15px;
}
.team-block-one .inner-box .image-box .social-links li:last-child {
  margin-bottom: 0;
}
.team-block-one .inner-box .image-box .social-links li a {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 50px;
  line-height: 50px;
  font-size: 18px;
  color: #333;
  background: #fff;
  text-align: center;
  border-radius: 50%;
}
.team-block-one .inner-box .image-box .social-links li a:hover {
  color: #fff;
}
.team-block-one .inner-box .lower-content {
  position: relative;
  display: block;
  padding: 22px 30px 25px;
}
.team-block-one .inner-box .lower-content h3 {
  position: relative;
  display: block;
  font-size: 24px;
  line-height: 34px;
  font-weight: 600;
  margin-bottom: 6px;
}
.team-block-one .inner-box .lower-content h3 a {
  display: inline-block;
  color: #222;
  font-size: 13px;
  text-align: center;
}
.team-block-one .inner-box .lower-content .designation {
  position: relative;
  display: block;
}
.team-page-section {
  padding: 143px 0 120px;
}
.team-page-section .team-block-one .inner-box {
  margin-bottom: 30px;
}
