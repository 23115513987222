.news-section,
.news-style-two {
  position: relative;
}
.news-block-one .inner-box {
  position: relative;
  display: block;
  padding: 0 30px;
}
.news-block-one .inner-box:before {
  position: absolute;
  content: "";
  background: #fff;
  width: 100%;
  height: calc(100% - 110px);
  left: 0;
  bottom: 0;
  box-shadow: 0 20px 80px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}
.news-block-one .inner-box .image-box,
.news-block-two .inner-box {
  position: relative;
  display: block;
}
.news-block-one .inner-box .image-box .image {
  position: relative;
  display: block;
  overflow: hidden;
  border-radius: 10px;
}
.news-block-one .inner-box .image-box .image img {
  width: 100%;
  transition: 0.5s;
}
.news-block-one .inner-box:hover .image-box .image img {
  opacity: 0.3;
  transform: scale(1.05);
}
.news-block-one .inner-box .image-box h2 {
  position: absolute;
  right: 30px;
  bottom: -20px;
  width: 80px;
  height: 80px;
  text-align: center;
  border-radius: 50%;
  font-size: 30px;
  line-height: 18px;
  font-weight: 600;
  color: #fff;
  z-index: 1;
  padding: 23px 0 10px;
}
.news-block-one .inner-box .image-box h2 span {
  display: block;
  font-size: 15px;
  font-weight: 400;
  font-family: Inter, sans-serif;
  text-transform: uppercase;
  margin-top: 5px;
}
.news-block-one .inner-box .lower-content {
  position: relative;
  display: block;
  padding-top: 40px;
  padding-bottom: 20px;
}
.news-block-one .inner-box .lower-content h3 {
  position: relative;
  display: block;
  font-size: 24px;
  line-height: 30px;
  font-weight: 600;
  margin-bottom: 12px;
}
.news-block-one .inner-box .lower-content h3 a,
.news-block-two .inner-box .lower-content h3 a {
  display: inline-block;
  color: #222;
}
.news-block-one .inner-box .lower-content p {
  position: relative;
  display: block;
  margin-bottom: 30px;
}
.news-block-one .inner-box .lower-content .post-info {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 15px;
  border-top: 1px solid #e5e5e5;
}
.news-block-one .inner-box .post-info li {
  position: relative;
  display: block;
  font-size: 16px;
  padding-left: 25px;
}
.news-block-one .inner-box .post-info li a {
  display: inline-block;
  color: #6e6e6e;
}
.news-block-one .inner-box .post-info li i,
.news-block-two .inner-box .lower-content .post-info li i {
  position: absolute;
  left: 0;
  top: 5px;
  font-size: 16px;
}
.news-block-two .inner-box .image-box {
  position: relative;
  display: block;
  overflow: hidden;
  background: #000;
  border-radius: 10px;
}
.news-block-two .inner-box .image-box img {
  width: 100%;
  border-radius: 10px;
  transition: 0.5s;
}
.news-block-two .inner-box:hover .image-box img {
  opacity: 0.8;
  transform: scale(1.05);
}
.news-block-two .inner-box .lower-content {
  position: relative;
  margin: 0 30px;
}
.news-block-two .inner-box .lower-content .inner {
  position: relative;
  background: #fff;
  box-shadow: 0 20px 80px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 30px 30px 25px;
  margin-top: -70px;
  z-index: 1;
  transition: 0.5s;
}
.news-block-two .inner-box:hover .lower-content .inner {
  padding-bottom: 102px;
  margin-top: -147px;
}
.news-block-two .inner-box .lower-content .post-date {
  position: relative;
  display: inline-block;
  font-size: 15px;
  line-height: 30px;
  font-weight: 500;
  background: rgba(222, 44, 44, 0.12);
  border-radius: 5px;
  padding: 0 18px;
  text-align: center;
  margin-bottom: 13px;
}
.news-block-two .inner-box .lower-content h3 {
  display: block;
  font-size: 24px;
  line-height: 34px;
  font-weight: 600;
  margin-bottom: 12px;
}
.news-block-two .inner-box .lower-content .post-info li {
  position: relative;
  display: inline-block;
  float: left;
  font-size: 16px;
  line-height: 26px;
  margin-right: 40px;
  padding-left: 25px;
}
.news-block-two .inner-box .lower-content .post-info li:last-child {
  margin: 0;
}
.news-block-two .inner-box .lower-content .post-info li a {
  position: relative;
  display: inline-block;
  color: #6e6e6e;
}
.news-block-two .inner-box .lower-content .post-info li:before {
  position: absolute;
  content: "";
  background: #d9d9d9;
  width: 1px;
  height: 16px;
  top: 5px;
  right: -21px;
}
.news-block-two .inner-box .lower-content .post-info li:last-child:before {
  display: none;
}
.news-block-two .inner-box .lower-content .post-info {
  margin-bottom: 10px;
}
.news-block-two .inner-box .lower-content .btn-box {
  position: absolute;
  left: 30px;
  bottom: 0;
  opacity: 0;
  transition: 0.5s;
}
.news-block-two .inner-box:hover .lower-content .btn-box {
  opacity: 1;
  bottom: 30px;
}
.news-block-two .inner-box .lower-content .btn-box .theme-btn-one {
  padding: 13px 34px;
}
