.about-style-three .content_block_one .content-box .text p:first-child:before,
.banner-carousel .owl-nav button:hover,
.banner-style-two.alternat-2 .owl-theme .owl-dots .owl-dot span:hover,
.banner-style-two.alternat-2 .owl-theme .owl-dots .owl-dot.active span,
.blog-details-content .news-block-one .inner-box blockquote h4:before,
.career-section .accordion-box .accordion .acc-btn.active .icon-box,
.chooseus-section .outer-box,
.content_block_two .content-box .inner-box .single-item .icon-box,
.feature-block-one .inner-box .lower-content .icon-box,
.feature-block-three .inner-box .icon-box,
.feature-block-two .inner-box .icon-box,
.handle-preloader,
.image_block_one .image-box .video-btn a,
.image_block_three .image-box .image-text,
.main-header .menu-right-content .btn-box a:before,
.nav-style-one .owl-nav button:hover,
.news-block-one .inner-box .image-box .image,
.news-block-one .inner-box .image-box h2,
.portfolio-details .nav-btn-box .single-btn a:hover,
.pricing-section .tab-btn-box .tab-btns li:before,
.progress-box .bar-inner,
.project-block-one .inner-box .content-box .view-btn a,
.project-block-three .inner-box .image-box .view-btn a,
.projects-section .owl-theme .owl-dots .owl-dot span,
.range-slider .ui-slider .ui-slider-range,
.service-block-one .inner-box .icon-box,
.service-block-two .inner-box .lower-content .icon-box,
.service-details-content .content-four .download-list li button:hover,
.service-details-content .content-three .single-item .icon-box,
.service-sidebar .category-widget .category-list li a:before,
.shop-block-one .inner-box .image-box .cart-btn button,
.shop-block-one .inner-box .image-box .option-list li a:hover,
.team-block-one .inner-box .image-box .social-links li a:hover,
.team-details .lower-content .inner-box .single-item:after,
.testimonial-block-one .inner-box .icon-box,
.theme-btn-one,
.working-block-one .inner-box .count-text h3 {
  background: #85c226;
}
.accordion-box .accordion .acc-btn.active .icon-box:before,
.banner-carousel .content-inner span,
.banner-style-two.alternat-2 .banner-carousel .content-box span,
.blog-details-content .news-block-one .inner-box blockquote .icon-box,
.blog-sidebar .category-widget .category-list li a:before,
.blog-sidebar .category-widget .category-list li a:hover,
.blog-sidebar .post-widget .post h5 a:hover,
.blog-sidebar .search-widget .form-group button:hover,
.blog-sidebar .search-widget .form-group input:focus + button,
.bootstrap-touchspin .glyphicon-chevron-down:hover:before,
.bootstrap-touchspin .glyphicon-chevron-up:hover:before,
.cart-section .cart-total .total-cart-box .list li:last-child span,
.checkout-section .order-info .order-list li.order-total span,
.checkout-section
  .payment-info
  .payment-inner
  .custom-controls-stacked
  .description
  a:hover,
.checkout-section .upper-box .single-box a,
.content_block_five .content-box .inner-box .single-item .icon-box,
.content_block_one .content-box .inner-box .single-item .icon-box,
.cta-section .inner-box h2 span,
.cta-section .inner-box h3 a:hover,
.feature-block-one .inner-box .lower-content h3 a:hover,
.feature-block-three .inner-box h3 a:hover,
.feature-block-three .inner-box p a:hover,
.feature-block-two .inner-box h3 a:hover,
.feature-block-two .inner-box:hover .icon-box,
.footer-bottom a:hover,
.funfact-block-one .inner-box .count-outer,
.header-style-three .main-menu .navigation > li.current > a,
.header-style-three .main-menu .navigation > li:hover > a,
.header-style-three .menu-right-content .cart-box a:hover,
.header-style-three .menu-right-content .search-box-outer:hover,
.header-top .info li a:hover,
.header-top .info li i,
.header-top .social-links li a:hover,
.header-top .top-right .login a:hover,
.image_block_two .image-box .image-content h2,
.list-style-one li:before,
.main-footer .contact-widget .info-list li a:hover,
.main-footer .contact-widget .info-list li i,
.main-footer .links-widget .links-list li a:hover,
.main-footer.alternat-2 .contact-widget .info-list li a:hover,
.main-footer.alternat-2 .footer-bottom p a:hover,
.main-footer.alternat-2 .links-widget .links-list li a:hover,
.main-header .menu-right-content .search-box-outer:hover,
.main-menu .navigation > li.current > a,
.main-menu .navigation > li:hover > a,
.main-menu .navigation > li > .megamenu li > a:hover,
.main-menu .navigation > li > ul > li > a:hover,
.main-menu .navigation > li > ul > li > ul > li > a:hover,
.news-block-one .inner-box .lower-content .post-info li i,
.news-block-one .inner-box .lower-content h3 a:hover,
.news-block-one .inner-box .post-info li a:hover,
.news-block-two .inner-box .lower-content .post-date,
.news-block-two .inner-box .lower-content .post-info li a:hover,
.news-block-two .inner-box .lower-content .post-info li i,
.news-block-two .inner-box .lower-content h3 a:hover,
.page-title .bread-crumb li a:hover,
.portfolio-details .nav-btn-box .single-btn a,
.preloader-close,
.pricing-block-one .pricing-table .title,
.project-block-one .inner-box .content-box h3 a:hover,
.project-block-three .inner-box .image-box .view-btn a:hover,
.project-block-three .inner-box .lower-content h3 a:hover,
.project-block-three .inner-box .lower-content span,
.project-block-two .inner-box h3 a:hover,
.sec-title .sub-title,
.service-block-three .inner-box .icon-box,
.service-block-three .inner-box h3 a:hover,
.service-block-two .inner-box .lower-content h3 a:hover,
.service-details-content .content-four .download-list li .icon-box,
.service-details-content .content-four .download-list li button,
.service-sidebar .contact-widget .inner-box p a:hover,
.service-sidebar .contact-widget .widget-content h3 a:hover,
.service-sidebar .contact-widget .widget-content h3 span,
.shop-block-one .inner-box .lower-content .price,
.shop-block-one .inner-box .lower-content h4 a:hover,
.shop-details-content .content-box .customer-rating .review a:hover,
.shop-details-content .content-box .price,
.shop-details-content .other-option li a:hover,
.shop-sidebar .category-widget .category-list li a:before,
.shop-sidebar .category-widget .category-list li a:hover,
.shop-sidebar .search-widget .search-form .form-group button:hover,
.shop-sidebar .search-widget .search-form .form-group input:focus + button,
.sortable-masonry .filter-btns li.active,
.team-block-one .inner-box .lower-content h3 a:hover,
.team-details-content .content-box .designation,
.team-details-content .content-box .info-list li a:hover,
.video-section .inner-container .video-btn a:hover {
  color: #85c226;
}
.main-menu .navigation > li > ul,
.main-menu .navigation > li > ul > li > ul {
  border-top: 3px solid #85c226;
}
.blog-details-content .news-block-one .inner-box .tags-list li a:hover,
.blog-sidebar .tags-widget .tags-list li a:hover,
.cart-section .cart-table tbody tr .prod-column .column-box .remove-btn:hover,
.cart-section .othre-content .theme-btn-two:hover,
.main-header .menu-right-content .btn-box a:hover,
.pagination li a.current,
.pagination li a:hover,
.portfolio-sidebar .social-links li a:hover,
.shop-details-content .addto-cart-box .like-box a:hover,
.team-details-content .content-box .social-links li a:hover {
  background: #85c226;
  border-color: #85c226;
}
.banner-style-two.alternat-2 .owl-theme .owl-dots .owl-dot span:hover:before,
.banner-style-two.alternat-2 .owl-theme .owl-dots .owl-dot.active span:before,
.checkout-section .additional-info .note-book textarea:focus,
.owl-dots-one .owl-dots .owl-dot.active span,
.owl-dots-one .owl-dots .owl-dot:hover span,
.projects-section .owl-theme .owl-dots .owl-dot.active span,
.projects-section .owl-theme .owl-dots .owl-dot:hover span,
.projects-section.alternat-2 .owl-theme .owl-dots .owl-dot.active span,
.projects-section.alternat-2 .owl-theme .owl-dots .owl-dot:hover span,
.service-sidebar .category-widget .category-list li a.current,
.service-sidebar .category-widget .category-list li a:hover {
  border-color: #85c226;
}
.working-block-one .inner-box .count-text h3:before {
  border: 2px dashed #85c226;
}
.blog-sidebar .search-widget .form-group input:focus,
.career-sidebar .form-inner .form-group input:focus,
.career-sidebar .form-inner .form-group textarea:focus,
.contact-section .form-inner .form-group input:focus,
.contact-section .form-inner .form-group textarea:focus,
.default-form .form-group input:focus,
.default-form .form-group textarea:focus,
.default-form input:focus,
.default-form textarea:focus,
.header-style-three .menu-right-content .theme-btn-one,
.subscribe-section .form-inner .form-group input:focus {
  border-color: #85c226 !important;
}
.header-style-two.main-header .menu-right-content .btn-box a {
  border: 1px solid #85c226;
}
.owl-dots-one .owl-dots .owl-dot span {
  background: #85c226 !important;
}
.service-style-three .more-btn .theme-btn-one:hover {
  color: #85c226 !important;
}
.team-details .lower-content .inner-box .single-item:before {
  border: 1.5px solid #85c226;
}
.range-slider .ui-state-default,
.range-slider .ui-widget-content .ui-state-default {
  border: 5px solid #85c226;
}
.menu-area .mobile-nav-toggler {
  background-color: #85c226;
}
