.contact-section .form-inner {
  position: relative;
  display: block;
  max-width: 850px;
  margin: 0 auto;
  background: #fff;
  border-radius: 10px;
  padding: 70px;
}
.contact-section .form-inner .form-group {
  position: relative;
  margin-bottom: 20px;
}
.contactTable {
  margin-top: 80px;
}
.tableLink {
  color: #000;
}
.contact-section .form-inner .form-group:last-child,
.contact-style-two .form-inner .form-group:last-child {
  margin-bottom: 0;
}
.contact-section .form-inner .message-btn {
  padding-top: 10px;
}
.contact-section .form-inner .form-group input[type="email"],
.contact-section .form-inner .form-group input[type="text"],
.contact-section .form-inner .form-group textarea {
  position: relative;
  display: block;
  width: 100%;
  height: 50px;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  font-size: 16px;
  color: #6e6e6e;
  padding: 10px 20px;
  text-align: left;
  transition: 0.5s;
}
.contact-section .form-inner .form-group textarea {
  height: 130px;
  resize: none;
}
.contact-section .form-inner .form-group .theme-btn-one {
  padding: 13px 40px;
}
.contact-section .pattern-layer {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.contact-style-two,
.google-map-section {
  position: relative;
}
.contact-style-two .social-links li {
  position: relative;
  display: inline-block;
  float: left;
  margin-right: 10px;
}
.contact-style-two .social-links li:last-child {
  margin: 0;
}
.contact-style-two .social-links li a {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 50px;
  line-height: 50px;
  font-size: 16px;
  color: #fff;
  text-align: center;
  border-radius: 50%;
  background: #3b5998;
}
.contact-style-two .social-links li:nth-child(2) a {
  background: #7dc45c;
}
.contact-style-two .social-links li:nth-child(3) a {
  background: #dc4e41;
}
.contact-style-two .social-links li:nth-child(4) a {
  background: #31e08f;
}
.contact-style-two .social-links li:last-child a {
  background: #0e0d0d;
}
.linkContact {
  color: #85c226;
  font-size: 15px;
}
.contact-style-two .form-inner textarea {
  height: 170px;
}
.contact-style-two .form-inner .form-group {
  margin-bottom: 30px;
}
.contactTextAria {
  width: 100%;
  border: 1px solid #80808059;
  color: gray;
  border-radius: 5px;
}
.contact-style-two .form-inner .form-group .theme-btn-one {
  padding: 13px 40px;
  box-shadow: none;
}
.google-map-section #contact-google-map {
  position: relative;
  width: 100%;
  height: 450px;
}
