.blog-details-content .comment-form-area .form-inner .form-group,
.blog-grid .news-block-two .inner-box {
  margin-bottom: 30px;
}
.sidebar-page-container {
  position: relative;
}
.sidebar-page-container .news-block-one .inner-box {
  padding: 0 50px;
  margin-bottom: 70px;
}
.sidebar-page-container .news-block-one .inner-box .lower-content .post-info {
  display: block;
  padding-top: 0;
  border-top: none;
  margin-bottom: 10px;
}
.sidebar-page-container
  .news-block-one
  .inner-box
  .lower-content
  .post-info
  li {
  float: left;
  margin-right: 40px;
}
.blog-details-content .news-block-one .inner-box .tags-list li:last-child,
.sidebar-page-container
  .news-block-one
  .inner-box
  .lower-content
  .post-info
  li:last-child {
  margin: 0;
}
.sidebar-page-container
  .news-block-one
  .inner-box
  .lower-content
  .post-info
  li:before {
  position: absolute;
  content: "";
  background: #d9d9d9;
  width: 1px;
  height: 20px;
  top: 4px;
  right: -21px;
}
.sidebar-page-container
  .news-block-one
  .inner-box
  .lower-content
  .post-info
  li:last-child:before {
  display: none;
}
.sidebar-page-container .news-block-one .inner-box .lower-content h3 {
  font-size: 28px;
  line-height: 38px;
}
.blog-details-content .comment-form-area .form-inner .form-group:last-child,
.blog-sidebar .post-widget .post:last-child,
.blog-sidebar .sidebar-widget:last-child,
.sidebar-page-container .news-block-one .inner-box .lower-content p {
  margin-bottom: 0;
}
.sidebar-page-container .news-block-one .inner-box .lower-content {
  padding-bottom: 42px;
}
.sidebar-page-container .news-block-one .inner-box:before {
  height: calc(100% - 230px);
}
.blog-details-content,
.blog-sidebar,
.blog-sidebar .post-widget .post .post-date {
  position: relative;
  display: block;
}
.blog-sidebar .sidebar-widget {
  position: relative;
  background: #fff;
  box-shadow: 0 20px 80px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin-bottom: 70px;
  padding: 40px;
}
.blog-sidebar .search-widget .form-group {
  position: relative;
  margin-bottom: 0;
}
.blog-sidebar .search-widget .form-group input[type="search"] {
  position: relative;
  display: block;
  width: 100%;
  height: 60px;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  font-size: 16px;
  color: #6e6e6e;
  padding: 10px 60px 10px 30px;
  transition: 0.5s;
}
.blog-sidebar .search-widget .form-group button {
  position: absolute;
  top: 18px;
  right: 20px;
  font-size: 18px;
  color: #222;
  cursor: pointer;
  transition: 0.5s;
}
.blog-sidebar .widget-title {
  position: relative;
  display: block;
  margin-bottom: 22px;
}
.blog-sidebar .widget-title h3 {
  font-size: 24px;
  line-height: 34px;
  font-weight: 600;
}
.blog-sidebar .category-widget .category-list li {
  position: relative;
  display: block;
  padding: 12px 0;
  border-bottom: 1px solid #e5e5e5;
}
.blog-sidebar .category-widget .category-list li:last-child {
  border-bottom: none;
}
.blog-sidebar .category-widget .category-list li a {
  position: relative;
  display: block;
  font-size: 16px;
  color: #222;
  font-weight: 500;
}
.blog-sidebar .category-widget .category-list li a:hover:before {
  opacity: 1;
}
.blog-sidebar .category-widget {
  padding-top: 32px;
  padding-bottom: 22px;
}
.blog-sidebar .category-widget .widget-title {
  margin-bottom: 13px;
}
.blog-sidebar .post-widget .post {
  position: relative;
  display: block;
  padding: 5px 0 5px 110px;
  min-height: 90px;
  margin-bottom: 30px;
}
.blog-sidebar .post-widget .post .post-thumb {
  position: absolute;
  left: 0;
  top: 0;
  width: 90px;
  height: 90px;
  border-radius: 50%;
}
.blog-details-content .author-box .author-thumb img,
.blog-sidebar .post-widget .post .post-thumb img {
  width: 100%;
  border-radius: 50%;
}
.blog-sidebar .post-widget .post h5 {
  display: block;
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  margin-bottom: 4px;
}
.blog-sidebar .post-widget .post h5 a {
  display: inline-block;
  color: #222;
}
.blog-sidebar .tags-widget .tags-list {
  position: relative;
  margin: 0 -5px;
}
.blog-sidebar .tags-widget .tags-list li {
  position: relative;
  float: left;
  margin: 0 5px 10px;
}
.blog-sidebar .tags-widget .tags-list li a {
  position: relative;
  display: inline-block;
  font-size: 16px;
  color: #222;
  border: 1px solid #e5e5e5;
  font-weight: 500;
  border-radius: 30px;
  padding: 3px 13px;
  text-align: center;
}
.blog-details-content .news-block-one .inner-box .tags-list li a:hover,
.blog-sidebar .tags-widget .tags-list li a:hover {
  color: #fff;
}
.blog-sidebar .tags-widget {
  padding-bottom: 30px;
}
.sidebar-page-container .news-block-one .inner-box .lower-content h2 {
  display: block;
  font-size: 40px;
  line-height: 50px;
  font-weight: 600;
  margin-bottom: 30px;
}
.blog-details-content .news-block-one .inner-box .lower-content p {
  margin-bottom: 26px;
}
.blog-details-content .news-block-one .inner-box blockquote {
  position: relative;
  display: block;
  background: rgba(241, 241, 241, 0.5);
  border-radius: 10px;
  padding: 50px 50px 44px;
  margin: 60px 0;
}
.blog-details-content .news-block-one .inner-box blockquote .icon-box {
  position: relative;
  display: block;
  font-size: 24px;
  margin-bottom: 18px;
}
.blog-details-content .news-block-one .inner-box blockquote p {
  font-size: 18px;
  line-height: 28px;
  color: #222;
  font-weight: 500;
  margin-bottom: 12px;
}
.blog-details-content .news-block-one .inner-box blockquote h4 {
  position: relative;
  display: block;
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
  padding-left: 68px;
}
.blog-details-content .news-block-one .inner-box blockquote h4:before {
  position: absolute;
  content: "";
  width: 50px;
  height: 2px;
  left: 0;
  top: 10px;
}
.blog-details-content .news-block-one .inner-box .tags-list li {
  position: relative;
  display: inline-block;
  float: left;
  margin-right: 10px;
}
.blog-details-content .news-block-one .inner-box .tags-list li a {
  position: relative;
  display: inline-block;
  font-size: 16px;
  color: #222;
  font-weight: 500;
  border: 1px solid #e5e5e5;
  border-radius: 30px;
  padding: 3px 13px;
  text-align: center;
}
.blog-details-content .author-box,
.blog-details-content .comment-form-area {
  background: #fff;
  box-shadow: 0 20px 80px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  display: block;
}
.blog-details-content .news-block-one .inner-box .tags-list {
  padding-top: 37px;
}
.sidebar-page-container
  .blog-details-content
  .news-block-one
  .inner-box
  .lower-content {
  padding-bottom: 70px;
}
.sidebar-page-container .news-block-one .inner-box:hover .image-box .image img {
  opacity: 1 !important;
  transform: scale(1) !important;
}
.blog-details-content .author-box {
  position: relative;
  padding: 45px 50px 45px 200px;
  margin-bottom: 70px;
}
.blog-details-content .author-box .author-thumb {
  position: absolute;
  left: 50px;
  top: 50px;
  width: 120px;
  height: 120px;
  border-radius: 50%;
}
.blog-details-content .author-box h3 {
  display: block;
  font-size: 24px;
  line-height: 34px;
  font-weight: 600;
}
.blog-details-content .author-box .designation {
  position: relative;
  display: block;
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 18px;
}
.blog-details-content .comment-form-area {
  position: relative;
  padding: 42px 50px 50px;
}
.blog-details-content .comment-form-area h3 {
  display: block;
  font-size: 28px;
  line-height: 38px;
  font-weight: 600;
  margin-bottom: 32px;
}
.blog-details-content .comment-form-area .form-inner .form-group textarea {
  height: 150px;
}
.blog-details-content .comment-form-area .form-inner .theme-btn-one {
  padding: 13px 47px;
}
